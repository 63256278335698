<template>
  <v-app-bar
      id="app-bar"
      absolute
      app
      color="transparent"
      flat
      height="75"
  >
    <v-btn
        class="mr-3"
        elevation="1"
        fab
        small
        @click="setDrawer(!drawer)"
    >
      <v-icon v-if="value">
        mdi-view-quilt
      </v-icon>

      <v-icon v-else>
        mdi-dots-vertical
      </v-icon>
    </v-btn>

    <v-toolbar-title
        class="hidden-sm-and-down font-weight-light"
        v-text="$route.meta.title"
        data-cy="page-title"
    />

    <v-spacer />


    <div class="mx-3" />

  </v-app-bar>
</template>

<script>

// Utilities
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'CoreAppBar',

  components: {},

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    notifications: [],
  }),

  computed: {
    ...mapState(['drawer']),
  },

  methods: {
    ...mapMutations({
      setDrawer: 'SET_DRAWER',
    }),
  },
}
</script>